import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import Title from '../Title';
import { WorkWrapper } from './style';

const WorkListWrapper = styled(WorkWrapper)`
  padding-top: 4rem;

  article:first-of-type {
    margin-top: 0rem;
  }
`;

import WorkCard from './WorkCard';

const index = ({ title, desc, data }) => {
  return (
    <WorkListWrapper>
      <div className="container">
        <Title title={title} desc={desc} align="center" />

        <div className="WorkGrid">
          {!data.allMarkdownRemark.nodes ? (
            <div>No data `./components/Work/worklist.js`</div>
          ) : (
            data.allMarkdownRemark.nodes?.map((node) => (
              <WorkCard
                key={uuidv4()}
                img={node.frontmatter.showCaseImage}
                logo={node.frontmatter.companyLogo}
                caption={node.frontmatter.keywords}
                title={node.frontmatter.shortTitle}
                caseStudy={`/case-studies${node.fields.slug}`}
                website={node.frontmatter.website}
              />
            ))
          )}
        </div>
      </div>
    </WorkListWrapper>
  );
};

export default index;

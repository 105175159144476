import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';
import Layout from '../layout/layout';
import Worklist from '../components/Work/worklist';
import Achievements from '../components/Achievements';

const worklayout = ({ data }) => {
  return (
    <Layout pageTitle={ROUTES.casestudies.title}>
      <SEO
        title={ROUTES.casestudies.title}
        description={ROUTES.casestudies.desc}
      />
      <Worklist title="Case Studies" data={data} />
      <Achievements
        title="Let’s take your digital experience to the next level"
        desc="Our team provides a wide range of services to help you maximize your business's potential. Whether you need web development services, enterprise resource planning, or UX design, we have the tools and experience to deliver your project under budget and ahead of schedule. Want to get started? Please fill out our form, and one of our engineers will get back to you within twenty-four hours."
        highlightBorder={false}
        showStamp={true}
        showProjectRequest={false}
        hideOnMobile={true}
        maxw="44rem"
      />
    </Layout>
  );
};

export default worklayout;

export const query = graphql`
  query WorkListQuery($limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/case-studies/.*.md$/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
    ) {
      nodes {
        frontmatter {
          date
          title
          shortTitle
          showCaseImage
          showCaseImageAlt
          companyLogo
          keywords
          website
          intro
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
`;
